import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { useMutation } from '@apollo/client';
import { useLightSetOnTime } from '../../../../api/modules/light/light.hooks';
import { useSwitchSetOnTime } from '../../../../api/modules/switch/switch.hooks';
import { defaultFavouriteSetOnTimeList } from '../../../../const';
import {
  ChannelTypeInternal,
  FavouritePositionType,
  SetFavouriteChannelPositionsMutation,
  SetFavouriteChannelPositionsMutationVariables,
} from '../../../../data-access/gql-types/graphql';
import { CHANNEL_SET_FAVOURITE_POSITIONS } from '../../../../data-access/mutations/channels';
import { useDevicesAndChannels } from '../../../../hooks';
import { useChannelDetailsContext } from '../../../../hooks/channel-details';
import { ChannelInterface, ChannelLightInterface, ChannelSwitchInterface } from '../../../../types';
import { parseFavouritePositions } from '../../../../utils/channels/helpers';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { TimeActivation } from '../time-activation';

const favouriteRequestType = {
  [ChannelTypeInternal.Switch]: FavouritePositionType.Switch,
  [ChannelTypeInternal.Light]: FavouritePositionType.Lights,
};

interface PropsInterface {
  channel: ChannelInterface;
}

export const ChannelTimesActivations: React.FC<PropsInterface> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');
  const { channelList, setChannelList } = useDevicesAndChannels();
  const switchOnTime = useSwitchSetOnTime();
  const lightOnTime = useLightSetOnTime();
  const { updateChannelDetails } = useChannelDetailsContext();
  const [setFavouritePositions] = useMutation<
    SetFavouriteChannelPositionsMutation,
    SetFavouriteChannelPositionsMutationVariables
  >(CHANNEL_SET_FAVOURITE_POSITIONS);

  const favouriteSetOnTimeList = useMemo(() => {
    if (channel.data.type === ChannelTypeInternal.Switch || channel.data.type === ChannelTypeInternal.Light) {
      return parseFavouritePositions(channel.data.favouritePositionList, defaultFavouriteSetOnTimeList, false);
    }

    return defaultFavouriteSetOnTimeList;
  }, [channel, channelList, setChannelList]);

  const onFavouriteTimeChange = useCallback(
    (value, index) => {
      if (!channel) return;
      const newFavouriteList = cloneDeep(favouriteSetOnTimeList);
      newFavouriteList[index] = value;

      setFavouritePositions({
        variables: {
          input: {
            favouritePositions: newFavouriteList.map((position) => ({
              favouritePositionType: favouriteRequestType[channel.data.type],
              value: position,
            })),
            channelId: channel?.id,
          },
        },
        onCompleted: (data) => {
          if (data.setFavouriteChannelPositions.ok) {
            toastSuccess({ content: t('favouriteSetSuccess') });

            setChannelList((prev) => {
              const tempList = cloneDeep(prev);
              const index = tempList.findIndex((x) => x.id === channel.id);

              if (index !== -1) {
                (tempList[index].data as ChannelSwitchInterface | ChannelLightInterface).favouritePositionList = [
                  ...newFavouriteList,
                ];
              }

              return tempList;
            });

            updateChannelDetails((prev) => {
              const temp = cloneDeep(prev);
              if (temp) {
                (temp.data as ChannelSwitchInterface | ChannelLightInterface).favouritePositionList = [
                  ...newFavouriteList,
                ];
              }

              return temp;
            });
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
            // TODO errors
          }
        },
      });
    },
    [channel, favouriteSetOnTimeList, setChannelList],
  );

  const onClick = (value) => {
    switch (channel.data.type) {
      case ChannelTypeInternal.Switch: {
        switchOnTime.mutate({
          channelId: channel.id,
          deviceId: channel.deviceId,
          timeInMilliseconds: value,
        });
        break;
      }
      case ChannelTypeInternal.Light: {
        lightOnTime.mutate({
          channelId: channel.id,
          deviceId: channel.deviceId,
          timeInMilliseconds: value,
        });
        break;
      }
    }
  };

  return (
    <div className="p-l-24 p-r-24">
      <h3 className="m-t-16 m-b-16">{t('timeActivation')}</h3>
      <div className="favourite">
        <div className="favourite__buttons favourite__buttons--switch">
          {favouriteSetOnTimeList.map((value, index) => {
            return (
              <TimeActivation
                key={index}
                value={value}
                index={index}
                onTimeChange={onFavouriteTimeChange}
                onBtnClick={onClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
