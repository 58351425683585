import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { cloneDeep } from 'lodash';
import { ChannelTypeInternal, OptimizerConfigValidation, UserChannel } from '../../data-access/gql-types/graphql';
import { ChannelInterface } from '../../types';
import { ChannelOptimizerInterface } from '../../types/channel/optimizer';
import { parseChannel, parseChannelState } from '../../utils/channels/helpers';
import { useChannelsState } from '../channels-state';
import { useDevicesAndChannels } from '../devices-and-channels';

interface ChannelDetailsProviderInterface {
  channel: ChannelInterface | undefined;
  setChannelDetails: (userChannel: UserChannel) => void;
  optimizerConfigValidation: OptimizerConfigValidation | undefined;
  setOptimizerConfigValidation: Dispatch<SetStateAction<OptimizerConfigValidation | undefined>>;
  updateChannelDetails: Dispatch<SetStateAction<ChannelInterface | undefined>>;
  clearChannelDetails: () => void;
}

const initialState: ChannelDetailsProviderInterface = {
  channel: undefined,
  setChannelDetails: () => null,
  optimizerConfigValidation: undefined,
  setOptimizerConfigValidation: () => null,
  updateChannelDetails: () => null,
  clearChannelDetails: () => null,
};

export const ChannelDetailsContext = createContext<ChannelDetailsProviderInterface>(initialState);

export const useChannelDetailsContext = (): ChannelDetailsProviderInterface => useContext(ChannelDetailsContext);

export const ChannelDetailsContextProvider: React.FC = ({ children }) => {
  const { setChannelList } = useDevicesAndChannels();
  const [channel, setChannel] = useState<ChannelInterface | undefined>(undefined);
  const { setChannelState } = useChannelsState();
  const [optimizerConfigValidation, setOptimizerConfigValidation] = useState<OptimizerConfigValidation | undefined>(
    undefined,
  );

  const setChannelDetails = (userChannel: UserChannel) => {
    const ch = parseChannel(userChannel);
    const state = parseChannelState(userChannel);

    if (ch) {
      setChannel(ch);

      setChannelList((prev) => {
        const tempList = cloneDeep(prev);
        const index = tempList.findIndex((x) => x.id === ch.id);

        if (index !== -1) tempList[index] = ch;
        return tempList;
      });

      if (state) {
        setChannelState((oldState) => {
          const newState = { ...oldState };

          newState[ch.id] = {
            ...newState[ch.id],
            ...state,
          };

          return newState;
        });
      }

      if (ch.data.type === ChannelTypeInternal.Optimizer) {
        setOptimizerConfigValidation((ch.data as ChannelOptimizerInterface).payload?.configValidation);
      }
    }
  };

  const clearChannelDetails = () => {
    setChannel(undefined);
    setOptimizerConfigValidation(undefined);
  };

  const values: ChannelDetailsProviderInterface = {
    channel,
    optimizerConfigValidation,
    setChannelDetails,
    updateChannelDetails: setChannel,
    setOptimizerConfigValidation,
    clearChannelDetails,
  };

  return <ChannelDetailsContext.Provider value={values}>{children}</ChannelDetailsContext.Provider>;
};
