import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { max, sumBy } from 'lodash';
import { VectorMeasurementResponseItem } from '../../../data-access/gql-types/graphql';
import { SelectedPhase } from '../../../modules/channel-details/measurement/types';
import { ecoLevels } from '../../../modules/channel-details/meter/current/utils';
import { IconArrowLink } from '../../icons';
import { IconTip } from '../../icons/icon-tip';
import { DonutChart } from '../chart';
import { DonutChartTwoDirections } from '../chart-two-direction';
import './index.scss';

interface PropsInterface {
  lazyValue: number;
  value: number;
  averageValue: number;
  percentageRatioActualToMaximum: number;
  maxScaleValue: number;
  label: string;
  unit: string;
  showEco: boolean;
  phaseMeasurements: VectorMeasurementResponseItem[] | undefined;
  selectedPhases: SelectedPhase[];
  changeSelectedParameter?: () => void;
  noFixedScaleValues?: boolean;
  ticks?: boolean;
  indicator?: boolean;
  axis?: boolean;
  color?: string;
  arrowDirection?: 'up' | 'down';
  fixed?: number;
  arrow?: boolean;
  clickIndicator?: boolean;
  twoDirections?: boolean;
}

export const DonutChartWrapper: React.FC<PropsInterface> = ({
  lazyValue,
  value,
  averageValue,
  percentageRatioActualToMaximum,
  maxScaleValue,
  label,
  unit,
  changeSelectedParameter,
  showEco = false,
  phaseMeasurements,
  selectedPhases,
  children,
  noFixedScaleValues,
  ticks = true,
  indicator = true,
  axis = true,
  color,
  arrowDirection,
  arrow = false,
  fixed,
  clickIndicator = false,
  twoDirections,
}) => {
  const [clickableTip, setClickableTip] = useState<boolean>(!!clickIndicator);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setClickableTip(false);
    }, 8000);

    return () => clearTimeout(timeout);
  }, []);

  const getFixedValue = (value) => {
    if (noFixedScaleValues) return +value.toFixed(3);
    const power = Math.floor(value).toString().length;
    return power > 1 ? +value.toFixed(0) : +value.toFixed(1);
  };

  const summaryBalance = useMemo(() => {
    return {
      plus: sumBy(
        phaseMeasurements?.filter((x) => x.value > 0),
        'value',
      ),
      minus: sumBy(
        phaseMeasurements?.filter((x) => x.value < 0),
        'value',
      ),
    };
  }, [phaseMeasurements]);

  const isSummary = useMemo(() => {
    return !selectedPhases.length || (selectedPhases.length === 1 && selectedPhases[0].value === 0);
  }, [selectedPhases]);

  const twoDirectionsMaxValue = useMemo(() => {
    const maxValue = max([Math.abs(summaryBalance.plus), Math.abs(summaryBalance.minus)]);
    return maxValue ? maxValue + 2 : maxScaleValue * 2;
  }, [summaryBalance, isSummary, maxScaleValue]);

  const scaleValues = useMemo(
    () =>
      !twoDirections
        ? [
            0,
            getFixedValue(maxScaleValue) * 0.25,
            getFixedValue(maxScaleValue) * 0.5,
            getFixedValue(maxScaleValue) * 0.75,
            getFixedValue(maxScaleValue) * 1,
          ]
        : [
            getFixedValue(twoDirectionsMaxValue) * -1,
            getFixedValue(twoDirectionsMaxValue) * -0.5,
            0,
            getFixedValue(twoDirectionsMaxValue) * 0.5,
            getFixedValue(twoDirectionsMaxValue) * 1,
          ],
    [maxScaleValue, noFixedScaleValues, twoDirections, twoDirectionsMaxValue],
  );

  const style = useMemo(() => {
    return showEco
      ? ({
          '--shadow': `3px 3px 15px rgba(${
            ecoLevels.find((level) => percentageRatioActualToMaximum <= level.maxValue)?.rgbValue
          }, 0.3)`,
        } as React.CSSProperties)
      : ({
          '--shadow': `5px 5px 15px rgba(0, 0, 0, 0.15)`,
        } as React.CSSProperties);
  }, [showEco, percentageRatioActualToMaximum]);

  const elementStyle = useMemo(() => {
    return selectedPhases.length > 1 || !selectedPhases.length
      ? null
      : ({
          '--color': selectedPhases[0]?.color,
        } as React.CSSProperties);
  }, [selectedPhases]);

  return (
    <div className="donut-chart-wrapper">
      <div className="donut-chart-wrapper__donut">
        {!twoDirections ? (
          <DonutChart
            value={lazyValue}
            maxScaleValue={maxScaleValue}
            averageValue={averageValue}
            phaseMeasurements={phaseMeasurements}
            selectedPhases={selectedPhases}
            ticks={ticks}
            indicator={indicator}
            color={color}
          />
        ) : (
          <DonutChartTwoDirections
            maxScaleValue={twoDirectionsMaxValue}
            averageValue={averageValue}
            phaseMeasurements={phaseMeasurements}
            selectedPhases={selectedPhases}
            ticks={ticks}
            indicator={indicator}
            summaryBalance={summaryBalance}
          />
        )}
      </div>
      {axis && (
        <div className="donut-chart-wrapper__axis">
          {scaleValues.map((scaleValue, index) => {
            const isSummary = selectedPhases.find((x) => x.value === 0);

            return (
              <div
                {...(elementStyle ? { style: elementStyle } : {})}
                className={classNames('donut-chart-wrapper__axis-element', {
                  'donut-chart-wrapper__axis-element--active': scaleValue <= lazyValue && !twoDirections,
                  'donut-chart-wrapper__axis-element--active-red':
                    twoDirections && value > 0 && scaleValue <= value && scaleValue >= 0 && !isSummary,
                  'donut-chart-wrapper__axis-element--active-green':
                    twoDirections && value < 0 && scaleValue >= value && scaleValue <= 0 && !isSummary,
                })}
                key={index}
              >
                {Math.round(scaleValue * 1000) / 1000}
              </div>
            );
          })}
        </div>
      )}
      <div className="donut-chart-wrapper__donut-element">
        <div className="donut-chart-wrapper__donut-element-bg" style={style} />
        <div className="donut-chart-wrapper__donut-element-info" style={style} onClick={changeSelectedParameter}>
          {label && <div className="donut-chart-wrapper__donut-element-text">{label}</div>}
          <div className="donut-chart-wrapper__donut-element-wrapper">
            <div className="donut-chart-wrapper__donut-element-value">
              {value.toFixed(fixed !== undefined ? fixed : 1)}
            </div>
            <div className="donut-chart-wrapper__donut-element-unit">{unit}</div>
          </div>
          {arrow && (
            <div
              className={classNames('donut-chart-wrapper__donut-element-icon', {
                up: arrowDirection === 'up',
                down: arrowDirection === 'down',
              })}
            >
              <IconArrowLink className="icon-arrow-link--accent" size={22} />
            </div>
          )}
        </div>
        {clickableTip && <IconTip pulse />}
      </div>
      {children}
    </div>
  );
};
