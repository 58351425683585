import React from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { AggregateVectorParameterTypeInternal } from '../../../../../../../data-access/gql-types/graphql';
import { aggregatedParameters } from '../../../../../measurement/utils';
import { ChartDataValues } from '../../../types';
import './index.scss';

export const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation('channel-details');
  const { t: tc } = useTranslation('common');

  const getValue = (el: ChartDataValues, parameters: ChartDataValues[]) => {
    if (el.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) {
      const activeEnergy =
        parameters.find((x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy)?.value || 0;

      return `${(el.value * activeEnergy).toFixed(aggregatedParameters[el.type].fixed)} ${
        aggregatedParameters[el.type].unit
      } (${el.value.toFixed(aggregatedParameters[el.type].fixed)} ${aggregatedParameters[el.type].unit}/MWh)`;
    }

    return `${el.value.toFixed(aggregatedParameters[el.type].fixed)} ${aggregatedParameters[el.type].unit}`;
  };

  if (active && payload?.length) {
    return (
      <div className="custom-tooltip">
        {payload[0].payload && (
          <div style={{ textAlign: 'center', width: '100%' }}>{payload[0].payload.tooltipTime}</div>
        )}
        {Object.entries(groupBy(payload[0].payload.values, 'index')).map(([key, value]) => (
          <div key={key} className="m-b-16">
            {key !== '0' ? <span>{`${t('phase')} ${key}`}</span> : null}
            {value.map((el: ChartDataValues) => (
              <div key={el.type} className="tooltip__element">
                <span style={{ color: el.color }}>{`${t(`energyMeasurements.types.summary.${el.type}`)}: `}</span>
                <span>{el.value ? getValue(el, value) : tc('noData')}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }

  return null;
};
